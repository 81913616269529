::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #666;
}

.MuiCardHeader-root{
  justify-content: 'space-between';
  width: 100%;
}

.MuiAccordionSummary-content{
  margin: 0px !important;
}

.css-8b651h-MuiInputBase-root-MuiOutlinedInput-root{
  height: 30px;
  border-radius: 0px;
}

.highlightClassName{
  background-color: coral;
}

.css-rvdz5i-MuiDataGrid-root .MuiDataGrid-columnSeparator {
  opacity: 0 !important;
}

.apexcharts-tooltip {
  color: #000;
}

/* Drawer Media Query */
@media (min-width: 768px) and (max-width: 1054px) {
 .css-18yrf4p {
 width: 700px;
 }
}

@media (min-width: 508px) and (max-width: 767px) {
 .css-18yrf4p {
  width: 500px;
 }
}

@media (max-width: 507px) {
 .css-18yrf4p {
  width: 300px;
 }
}

.css-8b651h-MuiInputBase-root-MuiOutlinedInput-root{
 height: 40px;
 border-radius: 0px;
}

.drawerButtons {
  text-align: right;
}

.css-rvdz5i-MuiDataGrid-root .MuiDataGrid-columnSeparator {
  opacity: 0 !important;
}
/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #ddd}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show { display: block; }

.MuiList-root {
  z-index: 1400;
}

.rdg-header-row {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
  font-weight: 500;
  font-size: 0.875rem;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  text-align: left;
  white-space: nowrap;
}

.rdg-header-row .rdg-cell{
  z-index: 2;
}

.rdg-header-row .rdg-cell-frozen {
  z-index: 3;
}

.rdg-cell .MuiOutlinedInput-root {
  border-radius: 0px;
}

.rdg-cell .MuiOutlinedInput-input {
  padding: 6px 14px;
}

.rdg-cell-required {
  border: 1px solid red !important;
}

.rdg-light .rdg-header-row {
  color: #172b4d;
  background-color: #f4f5f7;
}

.rdg-dark .rdg-header-row {
  color: #fff;
  background-color: #000;
  border-top: none;
}

.rdg-header-row-custom {
  --rdg-grid-row-start: 1;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
  font-weight: 500;
  font-size: 0.875rem;
  text-align: left;
  color: #172b4d;
  white-space: nowrap;
  background-color: #f4f5f7;
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  align-items: center;
  height: 36px;
}

.rdg .rdg-cell-frozen  {
  z-index: 3;
}

.rdg-light .rdg-header-row-custom {
  color: #172b4d;
  background-color: #f4f5f7;
}

.rdg-dark .rdg-header-row-custom {
  color: rgba(221, 221, 221);
  background-color: #000;
  border-bottom: 0.2px solid #444;
  border-top: 0.2px solid #444;
}

.rdg {
  border-spacing: 0;
}

.rdg .rdg-row {
  border-collapse: collapse;
  border-spacing: 0;

}

.rdg .rdg-cell {
  border-left-style: hidden;
  border-right-style: hidden;
}

.rdg-light .rdg-row-odd {
  background-color: #fff;
}

.rdg-light .rdg-row-even {
  background-color: rgba(0, 0, 0, 0.04);
}

/* Custom style for the dark mode  */
.rdg-dark .rdg-row-even {
  background-color: rgba(255, 255, 255, 0.08);
  box-shadow: 0px 1px 2px rgb(0 0 0 / 12%), 0px 0px 0px 1px rgb(0 0 0 / 5%);  
}

.rdg-dark .rdg-row:hover {
  background-color: rgba(199, 153, 0, 0.15);
}

.announcement-view-message-body p {
  line-height: 8px;
}

/* For SideBar => custimize ScrollBar */
@property --var1 {
  syntax: "<color>";
  inherits: true;
  initial-value: transparent;
}

.hoverableScroll {
  overflow-y: scroll;
  overflow-x: hidden;
  transition: --var1 .35s;
}

.hoverableScroll:hover {
  --var1: #666;
}

.hoverableScroll::-webkit-scrollbar {
  background: transparent;
}

.hoverableScroll::-webkit-scrollbar-thumb {
  background: var(--var1);
  border-radius: 0.625rem;
}