.content {
  /* position: absolute; */
  
  /* height: 160px; */
  overflow: hidden;
  font-family: 'Lato', sans-serif;
  font-size: 32px;
    
  background: 'linear-gradient(to right, rgba(145, 133, 190, 0.7) 30%, rgba(189, 17, 48, 0.7) 100%)';
  background-clip: 'text';
  -webkit-background-clip: 'text';
  -webkit-text-fill-color: 'transparent';

}
.content__container {
  /* font-weight: 600; */
  overflow: hidden;
  height: 40px;
  /* padding: 0 40px; */
}

.content__container__text {
  display: inline;
  float: right;
  margin: 0;
  font-size: 36px;
}
.content__container__list {
  -webkit-animation-name: change;
  -webkit-animation-duration: 10s;
  -webkit-animation-iteration-count: infinite;
  animation-name: change;
  animation-duration: 10s;
  animation-iteration-count: infinite;

}

@-webkit-keyframes opacity {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
@-webkit-keyframes change {
  0%,
  10%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  12.5%,
  22.5% {
    transform: translate3d(0, -20%, 0);
  }
  25%,
  35% {
    transform: translate3d(0, -40%, 0);
  }
  37.5%,
  47.5% {
    transform: translate3d(0, -60%, 0);
  }
  50%,
  60% {
    transform: translate3d(0, -80%, 0);
  }
  62.5%,
  72.5% {
    transform: translate3d(0, -60%, 0);
  }
  75%,
  85% {
    transform: translate3d(0, -40%, 0);
  }
  87.5%,
  97.5% {
    transform: translate3d(0, -20%, 0);
  }
}
@keyframes opacity {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
@keyframes change {
  0%,
  10%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  12.5%,
  22.5% {
    transform: translate3d(0, -20%, 0);
  }
  25%,
  35% {
    transform: translate3d(0, -40%, 0);
  }
  37.5%,
  47.5% {
    transform: translate3d(0, -60%, 0);
  }
  50%,
  60% {
    transform: translate3d(0, -80%, 0);
  }
  62.5%,
  72.5% {
    transform: translate3d(0, -60%, 0);
  }
  75%,
  85% {
    transform: translate3d(0, -40%, 0);
  }
  87.5%,
  97.5% {
    transform: translate3d(0, -20%, 0);
  }
}
